import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/token/',
    method: 'post',
    data
  })
}

export function userInfo(data) {
  return request({
    url: '/app/user_info/',
    method: 'post'
  })
}

export function logout() {
  return request({
    url: '/token/black/',
    method: 'get'
  })
}

export function resetPsw(data) {
  return request({
    url: '/app/reset_psw/',
    method: 'get',
    params: data
  })
}
